import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import dateArray from "moment-array-dates";
import { Loading } from "../../component/loading";

class RosterInvidualThisWeekTab extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {};
  }

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  getRosterData = () => {
    const { rosters, user, branchs } = this.props;
    const start = moment().startOf("isoWeek");
    const end = moment().endOf("isoWeek");
    const dates = dateArray.range(start, end, "ddd DD MMM YYYY", true);
    const data = dates?.map((date) => {
      const roster = rosters?.find((item) => item.date === date && item.staff === user?.id);
      const workAt = roster?.workAt;
      const start = workAt && workAt.seconds && moment(workAt.toDate()).format("hh:mm A");
      const endAt = roster?.endAt;
      const finish = endAt && endAt.seconds && moment(endAt.toDate()).format("hh:mm A");
      const branch = branchs?.find((item) => item.id === roster?.branch);
      const shop = branch?.companyName;
      return {
        date: roster?.date,
        off: roster?.off,
        start,
        finish,
        shop,
      };
    });
    return data;
  };

  // ====================== Render Component ====================== //

  renderTable = () => {
    const { user, rosters } = this.props;
    if (rosters && user) {
      const fullName = `${user?.firstName} ${user?.lastName}`;
      return (
        <Paper style={{ marginBottom: 20 }}>
          <TableContainer className="report-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>{fullName}</TableCell>
                  <TableCell>Shop</TableCell>
                  <TableCell align="center">Start</TableCell>
                  <TableCell align="center">Finish</TableCell>
                </TableRow>
              </TableHead>
              {this.renderTableBody()}
            </Table>
          </TableContainer>
        </Paper>
      );
    }
    return <Loading loading={true} />;
  };

  renderTableBody = () => {
    const data = this.getRosterData();
    const { lock } = this.props;
    if (lock) {
      return (
        <TableBody>
          <TableRow hover>
            <TableCell align="left">No Data</TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>{data?.map((item, index) => this.renderTableRow({ item, index }))}</TableBody>
    );
  };

  renderTableRow = ({ item, index }) => {
    const date = item?.date;
    const off = item?.off;
    const start = item?.start;
    const finish = item?.finish;
    const shop = item?.shop;
    const color = off ? "red" : "green";
    const label = off ? "OFF" : start;
    const offLabel = off ? "-" : finish;
    return (
      <TableRow hover key={index}>
        <TableCell align="left">{date}</TableCell>
        <TableCell align="left">{shop}</TableCell>
        <TableCell align="center" style={{ color }}>
          {label}
        </TableCell>
        <TableCell align="center" style={{ color }}>
          {offLabel}
        </TableCell>
      </TableRow>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { active } = this.props;
    return <div className={active ? "tab-active" : "tab-in-active"}>{this.renderTable()}</div>;
  }
}

const mapStateToProps = (state) => ({
  user: state.firebaseWeb.user,
  rosters: state.firebaseWeb.rosters,
  branchs: state.firebaseWeb.branchs,
});

// const mapDispatchToProps = {

// }

export default compose(connect(mapStateToProps, null))(RosterInvidualThisWeekTab);
