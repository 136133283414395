import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import {
  ArrowBack,
  ArrowForward,
  TimerOutlined,
  TimerOffOutlined,
  BallotOutlined,
} from "@material-ui/icons";
import { Loading } from "../../component/loading";
import { ALL, WORK, OFF } from "../../config/stringConfig";

class RosterStoreThisWeekTab extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      weekDay: moment().isoWeekday(), // Slot của ngày trong tuần
      status: ALL,
    };
  }

  // ====================== Functions ====================== //

  // Handle switch
  preDay = () => {
    const { weekDay } = this.state;
    if (weekDay === 1) {
      return;
    }
    this.setState({
      weekDay: weekDay - 1,
    });
  };

  nextDay = () => {
    const { weekDay } = this.state;
    if (weekDay === 7) {
      return;
    }
    this.setState({
      weekDay: weekDay + 1,
    });
  };

  handleChangeStatus = ({ value }) => {
    const { status } = this.state;
    if (value === status) {
      return;
    }
    this.setState({
      status: value,
    });
  };

  getRosterData = () => {
    const { weekDay, status } = this.state;
    const { rosters, staffs, branch } = this.props;
    const date = weekDay && moment().isoWeekday(weekDay).format("ddd DD MMM YYYY");
    const data = staffs?.map((user) => {
      const fullName = `${user?.firstName} ${user?.lastName}`;
      const roster = rosters?.find((item) => item.date === date && item.staff === user?.id);
      const workAt = roster?.workAt;
      const start = workAt && workAt.seconds && moment(workAt.toDate()).format("hh:mm A");
      const endAt = roster?.endAt;
      const finish = endAt && endAt.seconds && moment(endAt.toDate()).format("hh:mm A");
      return {
        fullName,
        date: roster?.date,
        off: roster?.off,
        branch: roster?.branch,
        start,
        finish,
      };
    });
    if (status === OFF) {
      return data?.filter((item) => item.branch === branch && item.off);
    }
    if (status === WORK) {
      return data?.filter((item) => item.branch === branch && !item.off);
    }
    return data?.filter((item) => item.branch === branch);
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderDateSwitch = () => {
    const { weekDay } = this.state;
    const date = weekDay && moment().isoWeekday(weekDay);
    const dateString = date.format("ddd DD MMM YYYY");
    const isToday = moment().isSame(date);
    return (
      <div className="date-switch">
        <IconButton aria-label="pre-day" onClick={() => this.preDay()}>
          <ArrowBack
            size={25}
            style={{ height: 35, margin: 0, opacity: weekDay === 1 ? 0.2 : 1 }}
          />
        </IconButton>
        <div className="roster-date">
          <span style={{ textAlign: "center", color: isToday ? "#1976d2" : "#333" }}>
            {dateString}
          </span>
        </div>
        <IconButton aria-label="nextDay" onClick={() => this.nextDay()}>
          <ArrowForward
            size={25}
            style={{ height: 35, margin: 0, opacity: weekDay === 7 ? 0.2 : 1 }}
          />
        </IconButton>
      </div>
    );
  };

  renderFilterBar = () => {
    const { status } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
        <Grid item>{status}</Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button
              className={`filter-button ${status === ALL ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: ALL })}
            >
              <BallotOutlined />
            </Button>
            <Button
              className={`filter-button ${status === WORK ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: WORK })}
            >
              <TimerOutlined />
            </Button>
            <Button
              className={`filter-button ${status === OFF ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: OFF })}
            >
              <TimerOffOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderTable = () => {
    const { loading } = this.props;
    if (!loading) {
      const data = this.getRosterData();
      const length = data?.filter((item) => !item.off)?.length || 0;
      return (
        <Paper style={{ marginBottom: 20 }}>
          <TableContainer className="report-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Staff ({length})</TableCell>
                  <TableCell align="right">Start</TableCell>
                  <TableCell align="right">Finish</TableCell>
                </TableRow>
              </TableHead>
              {this.renderTableBody(data)}
            </Table>
          </TableContainer>
        </Paper>
      );
    }
    return <Loading loading={loading} />;
  };

  renderTableBody = (data) => {
    const { lock } = this.props;
    if (lock) {
      return (
        <TableBody>
          <TableRow hover>
            <TableCell align="left">No Data</TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>{data?.map((item, index) => this.renderTableRow({ item, index }))}</TableBody>
    );
  };

  renderTableRow = ({ item, index }) => {
    const fullName = item.fullName;
    const off = item?.off;
    const start = item?.start;
    const finish = item?.finish;
    const color = off ? "red" : "green";
    const label = off ? "OFF" : start;
    const offLabel = off ? "-" : finish;
    return (
      <TableRow hover key={index}>
        <TableCell align="left">{fullName}</TableCell>
        <TableCell align="right" style={{ color }}>
          {label}
        </TableCell>
        <TableCell align="right" style={{ color }}>
          {offLabel}
        </TableCell>
      </TableRow>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { active } = this.props;
    return (
      <div className={active ? "tab-active" : "tab-in-active"}>
        {this.renderDateSwitch()}
        {this.renderFilterBar()}
        {this.renderTable()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rosters: state.firebaseWeb.branchRosters,
});

// const mapDispatchToProps = {

// }

export default compose(connect(mapStateToProps, null))(RosterStoreThisWeekTab);
