import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Grid,
  FormControl,
  Select,
  IconButton,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Redirect } from "react-router-dom";
import { BRAND_NAME } from "../../config/config";
import {
  REQUEST_OFF,
  REQUEST_SICK,
  REQUEST_PLACE,
  REQUEST_TIME,
  REQUEST_SENT,
  REQUEST_ACCEPTED,
  REQUEST_REJECTED,
} from "../../config/stringConfig";
import { URL } from "../../config/config";
import Navbar from "../00_Nav/nav_bar";
import { getUserInfoFromStorage } from "../../store/actions/firebase.action";

class RequestDetailScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    this.props.getUserInfoFromStorage();
  };

  componentWillUnmount = () => {};

  // -------------------------- FUNCTIONS --------------------------

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  renderLeftIcon = () => {
    return (
      <IconButton
        color="inherit"
        aria-label="back drawer"
        edge="start"
        onClick={() => this.props.history.goBack()}
      >
        <ArrowBackIos style={{ color: "#fff" }} />
      </IconButton>
    );
  };

  renderTop = () => {
    return (
      <Grid container spacing={2} direction="row">
        {this.renderDatePicker()}
        {this.renderTypePicker()}
      </Grid>
    );
  };

  renderDatePicker = () => {
    const { request } = this.props;
    const onDate = request?.onDate;
    const date = onDate && onDate.seconds && moment(onDate.toDate());
    return (
      <Grid item md={6} xs={6}>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <DatePicker
            autoOk
            className="date-picker"
            variant="inline"
            inputVariant="outlined"
            fullWidth
            value={date}
            label="Date"
            format="ddd DD MMM YYYY"
            onChange={(date) => this.handleDateChange(date)}
            margin="dense"
            disabled
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };

  renderTypePicker = () => {
    const { request } = this.props;
    const type = request?.type;
    return (
      <Grid item md={6} xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="request-type-helper-label">Request Types</InputLabel>
          <Select
            labelId="request-type-helper-label"
            id="request-type-helper"
            native
            value={type}
            disabled
            margin="dense"
          >
            <option value={REQUEST_SICK}>{REQUEST_SICK}</option>
            <option value={REQUEST_PLACE}>{REQUEST_PLACE}</option>
            <option value={REQUEST_TIME}>{REQUEST_TIME}</option>
            <option value={REQUEST_OFF}>{REQUEST_OFF}</option>
          </Select>
        </FormControl>
      </Grid>
    );
  };

  renderNote = () => {
    const { request } = this.props;
    const note = request?.note;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="note"
            label="Notes"
            name="note"
            type="text"
            value={note}
            disabled
            onChange={this.handleChange}
            multiline
            rows={6}
          />
        </Grid>
      </Grid>
    );
  };

  renderStatus = () => {
    const { request } = this.props;
    const status = request?.status;
    let color = "#1976d2";
    switch (status) {
      case REQUEST_SENT:
        color = "#1976d2";
        break;
      case REQUEST_REJECTED:
        color = "red";
        break;
      case REQUEST_ACCEPTED:
        color = "green";
        break;
      default:
        break;
    }
    return (
      <p align="center" style={{ color, textAlign: "right", fontWeight: "bold" }}>
        {status}
      </p>
    );
  };

  // -------------------------- MAIN --------------------------
  render() {
    const { auth, request } = this.props;
    if (!auth?.uid) return <Redirect to={URL.signin} />;
    if (!request) return <Redirect to={URL.request} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm" className="bottom-nav">
          <Navbar
            title="Request Detail"
            subtitle={BRAND_NAME}
            left={this.renderLeftIcon()}
            right={null}
          />
          {this.renderStatus()}
          {this.renderTop()}
          {this.renderNote()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  user: state.firebaseWeb.user,
  request: state.firebaseWeb.request,
});

const mapDispatchToProps = {
  getUserInfoFromStorage,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetailScreen);
