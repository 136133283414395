import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Grid,
  FormControl,
  Select,
  IconButton,
  InputLabel,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBackIos, Send } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { Redirect } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { BRAND_NAME } from "../../config/config";
import Navbar from "../00_Nav/nav_bar";
import { getUserInfoFromStorage, firestoreAddRequest } from "../../store/actions/firebase.action";
import { REQUEST_OFF, REQUEST_SICK, REQUEST_PLACE, REQUEST_TIME } from "../../config/stringConfig";

class RequestAddScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      loading: false,
      date: moment(),
      type: REQUEST_SICK,
      note: "",
    };
  }

  componentDidMount = () => {
    this.props.getUserInfoFromStorage();
  };

  componentWillUnmount = () => {};

  // -------------------------- FUNCTIONS --------------------------

  handleDateChange = (date) => {
    this.setState({
      date,
    });
  };

  handleSelectType = (e) => {
    this.setState({
      type: e.target.value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  sentRequest = () => {
    const { user } = this.props;
    const { note, type, date } = this.state;
    const onDate = moment(date)._d;
    const request = {
      note,
      type,
      onDate,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreAddRequest({ request, user }, () => {
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.history.goBack();
            }
          );
        });
      }
    );
  };

  // -------------------------- RENDER --------------------------

  renderLeftIcon = () => {
    return (
      <IconButton
        color="inherit"
        aria-label="back drawer"
        edge="start"
        onClick={() => this.props.history.goBack()}
      >
        <ArrowBackIos style={{ color: "#fff" }} />
      </IconButton>
    );
  };

  renderTop = () => {
    return (
      <Grid container spacing={2} direction="row">
        {this.renderDatePicker()}
        {this.renderTypePicker()}
      </Grid>
    );
  };

  renderDatePicker = () => {
    const { date } = this.state;
    return (
      <Grid item md={6} xs={6}>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <DatePicker
            autoOk
            className="date-picker"
            variant="inline"
            inputVariant="outlined"
            fullWidth
            value={date}
            label="Date"
            format="ddd DD MMM YYYY"
            onChange={(date) => this.handleDateChange(date)}
            margin="dense"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };

  renderTypePicker = () => {
    const { type } = this.state;
    return (
      <Grid item md={6} xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="request-type-helper-label">Request Types</InputLabel>
          <Select
            labelId="request-type-helper-label"
            id="request-type-helper"
            native
            value={type}
            onChange={(event) => this.handleSelectType(event)}
            margin="dense"
          >
            <option value={REQUEST_SICK}>{REQUEST_SICK}</option>
            <option value={REQUEST_PLACE}>{REQUEST_PLACE}</option>
            <option value={REQUEST_TIME}>{REQUEST_TIME}</option>
            <option value={REQUEST_OFF}>{REQUEST_OFF}</option>
          </Select>
        </FormControl>
      </Grid>
    );
  };

  renderNote = () => {
    const { note } = this.state;
    return (
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="note"
            label="Notes"
            name="note"
            type="text"
            value={note}
            onChange={this.handleChange}
            multiline
            rows={6}
          />
        </Grid>
      </Grid>
    );
  };

  renderButtonBar = () => {
    return (
      <Grid container spacing={1} direction="row" justify="flex-end" style={{ marginTop: 10 }}>
        <Grid item xs={3}>
          {this.renderButton()}
        </Grid>
      </Grid>
    );
  };

  renderButton = () => {
    const { note, loading } = this.state;
    if (loading) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          fullWidth
          startIcon={<CircularProgress size={15} />}
        >
          SENT
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={!note}
        fullWidth
        onClick={() => this.sentRequest()}
        style={{ fontSize: 13 }}
        startIcon={<Send size={15} />}
      >
        SENT
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------
  render() {
    const { auth } = this.props;
    if (!auth?.uid) return <Redirect to={URL.signin} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm" className="bottom-nav">
          <Navbar
            title="New Request"
            subtitle={BRAND_NAME}
            left={this.renderLeftIcon()}
            right={null}
          />
          {this.renderTop()}
          {this.renderNote()}
          {this.renderButtonBar()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  user: state.firebaseWeb.user,
});

const mapDispatchToProps = {
  getUserInfoFromStorage,
  firestoreAddRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestAddScreen);
