import { combineReducers } from "redux";
import firebaseWeb from "./firebase.reducer";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import userReducer from "./user.reducer";

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  firebaseWeb,
  userReducer,
});

export default rootReducer;
