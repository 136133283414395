/* eslint-disable no-unused-vars */
import firebase from "firebase";
import moment from "moment";
import { orderBy } from "lodash";
import { REF_USER, GET_USER_SUCCESS } from "../ref";
// ========================== User ==========================

// -------------------------- Request --------------------------

const subUser = [];
export const firestoreSnapshotUser =
  ({ id }, callback) =>
  (dispatch) => {
    const unSub = firebase
      .firestore()
      .collection(REF_USER)
      .doc(id)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const data = snapshot.data();
          getUserInfoSuccess(dispatch, data);
          if (callback) {
            callback();
          }
        }
      });
    subUser.push(unSub);
  };

export const unSubUser = () => (dispatch) => {
  subUser.forEach((subscriber) => {
    subscriber();
  });
  subUser.length = 0;
};

const getUserInfoSuccess = (dispatch, data) => {
  dispatch({
    type: GET_USER_SUCCESS,
    payload: data,
  });
};
