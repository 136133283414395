import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Button,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { REQUEST_SENT, REQUEST_REJECTED, REQUEST_ACCEPTED } from "../../config/stringConfig";
import { URL, BRAND_NAME } from "../../config/config";
import Navbar from "../00_Nav/nav_bar";
import { Loading } from "../../component/loading";
import {
  getUserInfoFromStorage,
  firestoreSnapshotRequest,
  unSubRequest,
  setRequestDetail,
} from "../../store/actions/firebase.action";

class RequestScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    this.props.getUserInfoFromStorage((user) => {
      if (user) {
        this.setState(
          {
            loading: true,
          },
          () => {
            this.props.firestoreSnapshotRequest({ user }, () => {
              this.setState({
                loading: false,
              });
            });
          }
        );
      }
    });
  };

  componentWillUnmount = () => {
    this.props.unSubRequest();
  };

  // -------------------------- FUNCTIONS --------------------------

  renderRightButton = () => {
    return (
      <Button
        variant="outlined"
        startIcon={<Add style={{ color: "#fff" }} />}
        onClick={() => this.props.history.push(URL.requestAdd)}
        style={{ color: "#fff", borderColor: "#fff" }}
      >
        New Request
      </Button>
    );
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  openDetail = ({ detail }) => {
    this.props.setRequestDetail({ detail }, () => {
      this.props.history.push(URL.requestDetail);
    });
  };

  // -------------------------- RENDER --------------------------

  renderTable = () => {
    return (
      <Paper style={{ marginBottom: 20 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Request on</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            {this.renderTableBody()}
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  renderTableBody = () => {
    const { requests } = this.props;
    const { loading } = this.state;
    if (!loading) {
      return (
        <TableBody>
          {requests?.map((item, index) => this.renderTableRow({ item, index }))}
        </TableBody>
      );
    }
    return <Loading loading={true} />;
  };

  renderTableRow = ({ item, index }) => {
    const onDate = item?.onDate;
    const dateString = onDate && onDate.seconds && moment(onDate.toDate()).format("ddd DD/MM/YYYY");
    const type = item?.type;
    const status = item?.status;
    let color = "#1976d2";
    switch (status) {
      case REQUEST_SENT:
        color = "#1976d2";
        break;
      case REQUEST_REJECTED:
        color = "red";
        break;
      case REQUEST_ACCEPTED:
        color = "green";
        break;
      default:
        break;
    }
    return (
      <TableRow hover key={index} onClick={() => this.openDetail({ detail: item })}>
        <TableCell align="left">{dateString}</TableCell>
        <TableCell align="center">{type}</TableCell>
        <TableCell align="center" style={{ color, fontWeight: "bold" }}>
          {status}
        </TableCell>
      </TableRow>
    );
  };

  // -------------------------- MAIN --------------------------
  render() {
    const { auth } = this.props;
    if (!auth?.uid) return <Redirect to={URL.signin} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm" className="bottom-nav">
          <Navbar title="Request" subtitle={BRAND_NAME} right={this.renderRightButton()} />
          {this.renderTable()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  user: state.firebaseWeb.user,
  requests: state.firebaseWeb.requests,
});

const mapDispatchToProps = {
  getUserInfoFromStorage,
  firestoreSnapshotRequest,
  unSubRequest,
  setRequestDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestScreen);
