/* eslint-disable no-unused-vars */
import firebase from "firebase";
import moment from "moment";
import { orderBy } from "lodash";
import { LOCAL_USER, SYSTEM_EMAIL, SYSTEM_PASSWORD } from "../../config/stringConfig";
import { OWNER } from "../../config/config";
import { REQUEST_SENT, LEVELS } from "../../config/stringConfig";
import {
  REF_LOCK_ROSTER,
  LOCK_ROSTER_THIS_WEEK_SUCCESS,
  LOCK_ROSTER_NEXT_WEEK_SUCCESS,
} from "../ref";

// ========================== User ==========================

export const firebaseLogin =
  ({ user }, callback) =>
  (dispatch) => {
    firebaseLoading(dispatch);
    firebase
      .auth()
      .signInWithEmailAndPassword(SYSTEM_EMAIL, SYSTEM_PASSWORD)
      .then(() => {
        if (user) {
          const userJson = JSON.stringify(user);
          localStorage.setItem(LOCAL_USER, userJson);
          getUserInfoSuccess(dispatch, user);
          if (callback) {
            callback(user);
          }
        } else {
          firebase.auth().signOut();
          firebaseLoginFail(
            dispatch,
            "Wrong password or your account has not been created. Please contact the administrator."
          );
          if (callback) {
            callback(false);
          }
        }
      }) // Thành công
      .catch((error) => {
        firebaseLoginFail(dispatch, error.message);
        if (callback) {
          callback(false);
        }
      }); // Lỗi
  };

const firebaseLoading = (dispatch) => {
  dispatch({
    type: "firebase_loading",
  });
};

const getUserInfoSuccess = (dispatch, user) => {
  dispatch({
    type: "firebase_login_success",
    payload: user,
  });
};

// Đăng nhập thất bại
const firebaseLoginFail = (dispatch, error) => {
  dispatch({
    type: "firebase_login_fail",
    payload: error,
  });
};

export const firebaseReAuth =
  ({ email, password }, callback) =>
  (dispatch) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        if (callback) {
          callback(true);
        }
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
      });
  };

const subscribers = [];
export const getUserInfoFromStorage = (callback) => (dispatch) => {
  const userJson = localStorage.getItem(LOCAL_USER);
  if (userJson) {
    const user = JSON.parse(userJson);
    getUserInfoSuccess(dispatch, user);
    if (callback) {
      callback(user);
    }
  } else {
    logout();
  }
};

export const firebaseLogout = () => (dispatch) => {
  unSnapShotFirebase(); // unSub tất cả
  firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.clear();
    });
  firebaseLogoutSuccess(dispatch);
};

// Đăng xuất thành công
const firebaseLogoutSuccess = (dispatch) => {
  dispatch({
    type: "firebase_logout_success",
  });
};

// -------------------------- Snapshot control --------------------------

const unSnapShotFirebase = () => {
  // unsub rosters
  subscribeRosters.forEach((subscriber) => {
    subscriber();
  });
  subscribeRosters.length = 0;
};

const logout = () => {
  unSnapShotFirebase(); // un sub tất cả
  firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.clear();
    });
};

// -------------------------- Roster --------------------------
const subscribeRosters = [];
export const firestoreSnapshotRoster =
  ({ user }, callback) =>
  (dispatch) => {
    const owner = user?.owner;
    const staff = user?.id;
    const start = moment().startOf("isoWeek").startOf("day")._d;
    const end = moment().add(1, "week").endOf("isoWeek").endOf("day")._d;
    const unsubRoster = firebase
      .firestore()
      .collection("roster")
      .where("owner", "==", owner)
      .where("staff", "==", staff)
      .where("workAt", ">=", start)
      .where("workAt", "<=", end)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const filter = data.filter((item) => {
            const unPublict = item?.unPublict;
            return unPublict ? false : true;
          });
          snapshotRosterSuccess(dispatch, filter);
          if (callback) {
            callback();
          }
        }
      });
    subscribeRosters.push(unsubRoster);
  };

export const unSubRosters = () => (dispatch) => {
  subscribeRosters.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeRosters.length = 0;
};

const snapshotRosterSuccess = (dispatch, data) => {
  dispatch({
    type: "snapshot_roster_success",
    payload: data,
  });
};

const subscribeBranchRosters = [];
export const firestoreSnapshotBranchRoster =
  ({ user, branch }, callback) =>
  (dispatch) => {
    const owner = user?.owner;
    const start = moment().startOf("isoWeek").startOf("day")._d;
    const end = moment().add(1, "week").endOf("isoWeek").endOf("day")._d;
    const unsubRosterBranch = firebase
      .firestore()
      .collection("roster")
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("workAt", ">=", start)
      .where("workAt", "<=", end)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          snapshotBranchRosterSuccess(dispatch, data);
          if (callback) {
            callback();
          }
        }
      });
    subscribeBranchRosters.push(unsubRosterBranch);
  };

export const unSubBranchRosters = () => (dispatch) => {
  subscribeBranchRosters.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeBranchRosters.length = 0;
};

const snapshotBranchRosterSuccess = (dispatch, data) => {
  dispatch({
    type: "snapshot_branch_roster_success",
    payload: data,
  });
};

// -------------------------- Branch --------------------------

export const firestoreSnapshotBranchList = (callback) => (dispatch) => {
  firebase
    .firestore()
    .collection("branch")
    .where("owner", "==", OWNER)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        snapshotBranchSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
};

const snapshotBranchSuccess = (dispatch, data) => {
  dispatch({
    type: "snapshot_branch_list_success",
    payload: data,
  });
};

// -------------------------- Staffs --------------------------
export const firestoreSnapShotUserList = (callback) => (dispatch) => {
  firebase
    .firestore()
    .collection("user")
    .where("owner", "==", OWNER)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        const ordered = orderBy(
          data,
          [
            (item) => {
              return LEVELS.findIndex((l) => l.name === item?.level);
            },
            (item) => {
              return `${item?.firstName} ${item?.lastName}`;
            },
          ],
          ["desc", "asc"]
        );
        getGetUserListSuccess(dispatch, ordered);
      }
      if (callback) {
        callback();
      }
    });
};

const getGetUserListSuccess = (dispatch, data) => {
  dispatch({
    type: "get_user_list_success",
    payload: data,
  });
};

// -------------------------- Request --------------------------

const subscribeRequest = [];
export const firestoreSnapshotRequest =
  ({ user }, callback) =>
  (dispatch) => {
    const owner = user?.owner;
    const staff = user?.id;
    const start = moment().subtract(3, "months")._d;
    const end = moment()._d;
    const unsubRequest = firebase
      .firestore()
      .collection("request")
      .where("owner", "==", owner)
      .where("staff", "==", staff)
      .where("createAt", ">=", start)
      .where("createAt", "<=", end)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          snapshotRequestSuccess(dispatch, data);
          if (callback) {
            callback();
          }
        }
      });
    subscribeRequest.push(unsubRequest);
  };

export const unSubRequest = () => (dispatch) => {
  subscribeRequest.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeRequest.length = 0;
};

const snapshotRequestSuccess = (dispatch, data) => {
  dispatch({
    type: "snapshot_request_success",
    payload: data,
  });
};

export const firestoreAddRequest =
  ({ request, user }, callback) =>
  (dispatch) => {
    const requestRef = firebase.firestore().collection("request");
    const id = requestRef.doc().id;
    const staff = user?.id;
    const owner = user?.owner;
    const fullName = `${user?.firstName} ${user?.lastName}`;
    const data = {
      ...request,
      id,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      status: REQUEST_SENT,
      staff,
      fullName,
      owner,
    };
    requestRef
      .doc(id)
      .set(data, { merge: true })
      .then(() => {
        if (callback) {
          callback(true);
        }
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
      });
  };

export const setRequestDetail =
  ({ detail }, callback) =>
  (dispatch) => {
    setRequestDetailSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

const setRequestDetailSuccess = (dispatch, data) => {
  dispatch({
    type: "set_request_detail_success",
    payload: data,
  });
};

// -------------------------- Roster Lock--------------------------

const subscribeLock = [];
export const firestoreSnapShotLockRoster =
  ({ user }, callback) =>
  (dispatch) => {
    const owner = user?.owner;
    // This week
    const startThis = moment().startOf("isoWeek").startOf("day")._d;
    const endThis = moment().endOf("isoWeek").endOf("day")._d;
    const lockRef = firebase.firestore().collection(REF_LOCK_ROSTER);
    const unsubThis = lockRef
      .where("owner", "==", owner)
      .where("start", ">=", startThis)
      .where("start", "<=", endThis)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const lock = data[0] || null;
          getGetLockRosterThisSuccess(dispatch, lock);
        }
      });
    subscribeLock.push(unsubThis);
    // Next week
    const startNext = moment().add(1, "week").startOf("isoWeek").startOf("day")._d;
    const endNext = moment().add(1, "week").endOf("isoWeek").endOf("day")._d;
    const unsubNext = lockRef
      .where("owner", "==", owner)
      .where("start", ">=", startNext)
      .where("start", "<=", endNext)
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const lock = data[0] || null;
          getGetLockRosterNextSuccess(dispatch, lock);
        }
      });
    subscribeLock.push(unsubNext);
  };

const getGetLockRosterThisSuccess = (dispatch, data) => {
  dispatch({
    type: LOCK_ROSTER_THIS_WEEK_SUCCESS,
    payload: data,
  });
};

const getGetLockRosterNextSuccess = (dispatch, data) => {
  dispatch({
    type: LOCK_ROSTER_NEXT_WEEK_SUCCESS,
    payload: data,
  });
};

export const unSnapShotLockRoster = () => (dispatch) => {
  subscribeLock.forEach((subscriber) => {
    // Xóa bỏ subscribe snapshot nếu out
    subscriber();
  });
  subscribeLock.length = 0;
};
