import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  CssBaseline,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  Button,
} from "@material-ui/core";
import { TodayOutlined, LiveHelp } from "@material-ui/icons";
import Navbar from "../00_Nav/nav_bar";
import {
  getUserInfoFromStorage,
  firestoreSnapshotRoster,
  firestoreSnapshotBranchRoster,
  firestoreSnapShotLockRoster,
  unSubRosters,
  unSubBranchRosters,
  unSnapShotLockRoster,
  firebaseLogout,
} from "../../store/actions/firebase.action";
import { firestoreSnapshotUser, unSubUser } from "../../store/actions/user.action";
import { URL, BRAND_NAME } from "../../config/config";
import {
  RADIO_INVIDUAL,
  RADIO_SHOP,
  TAB_THIS_WEEK,
  TAB_NEXT_WEEK,
} from "../../config/stringConfig";
import RosterInvidualThisWeekTab from "./roster_invidual_this_week";
import RosterInvidualNextWeekTab from "./roster_invidual_next_week";
import RosterStoreThisWeekTab from "./roster_store_this_week";
import RosterStoreNextWeekTab from "./roster_store_next_week";

class RosterScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      loading: false,
      tab: TAB_THIS_WEEK,
      type: RADIO_INVIDUAL,
      staffsList: null,
    };
  }

  componentDidMount = () => {
    this.props.getUserInfoFromStorage((user) => {
      if (user) {
        const id = user.id;
        this.props.firestoreSnapshotUser({ id }, () => {
          this.getType();
        });
        this.props.firestoreSnapShotLockRoster({ user });
        this.props.firestoreSnapshotRoster({ user });
      } else {
        this.props.firebaseLogout();
      }
    });
  };

  componentWillUnmount = () => {
    this.props.unSubRosters();
    this.props.unSnapShotLockRoster();
    this.props.unSubUser();
  };

  getType = () => {
    const { user } = this.props;
    const individualRoster = user?.individualRoster;
    const type = individualRoster ? RADIO_INVIDUAL : RADIO_SHOP;
    this.setState({
      type,
    });
  };

  // -------------------------- FUNCTIONS --------------------------

  handleChangeTab = (event, tab) => {
    this.setState({
      tab,
    });
  };

  handleChangeType = (e) => {
    this.setState({
      type: e.target.value,
    });
  };

  handleSelectBranch = (e) => {
    const { branchs, staffs } = this.props;
    const selectedBranch = branchs?.find((item) => item.id === e.target.value);
    const branchStaffs = selectedBranch?.staffs;
    const staffsList = staffs?.filter((item) => branchStaffs?.includes(item.id));
    this.setState(
      {
        branch: e.target.value,
        staffsList,
      },
      () => this.firebaseSnapShotBranchRoster()
    );
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  firebaseSnapShotBranchRoster = () => {
    const { user } = this.props;
    const { branch } = this.state;
    this.setState(
      {
        loadingBranch: true,
      },
      () => {
        this.props.unSubBranchRosters();
        setTimeout(() => {
          this.props.firestoreSnapshotBranchRoster({ user, branch }, () => {
            this.setState({
              loadingBranch: false,
            });
          });
        }, 200);
      }
    );
  };

  // -------------------------- RENDER --------------------------

  renderRightButton = () => {
    return (
      <Button
        variant="outlined"
        startIcon={<LiveHelp style={{ color: "#fff" }} />}
        onClick={() => this.props.history.push(URL.request)}
        style={{ color: "#fff", borderColor: "#fff" }}
      >
        Request
      </Button>
    );
  };

  renderSelectMode = () => {
    return (
      <div style={{ flexDirection: "row", display: "flex" }}>
        {this.renderRadioGroup()}
        {this.renderBranchPicker()}
      </div>
    );
  };

  renderRadioGroup = () => {
    const { user } = this.props;
    const { type } = this.state;
    const individualRoster = user?.individualRoster;
    const shopRoster = user?.shopRoster;
    if (individualRoster && shopRoster) {
      return (
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="end"
          style={{ display: "table", minWidth: 200 }}
          onChange={this.handleChangeType}
        >
          <FormControlLabel
            value={RADIO_INVIDUAL}
            control={<Radio color="primary" />}
            label="Invidual"
            labelPlacement="end"
            checked={type === RADIO_INVIDUAL}
          />
          <FormControlLabel
            value={RADIO_SHOP}
            control={<Radio color="primary" />}
            label="Shop"
            labelPlacement="end"
            checked={type === RADIO_SHOP}
          />
        </RadioGroup>
      );
    }
    return null;
  };

  // renderRadioIndividual = () => {
  //   const { user } = this.props;
  //   const { type } = this.state;
  //   const individualRoster = user?.individualRoster;
  //   if (individualRoster === true) {
  //     return (
  //       <FormControlLabel
  //         value={RADIO_INVIDUAL}
  //         control={<Radio color="primary" />}
  //         label="Invidual"
  //         labelPlacement="end"
  //         checked={type === RADIO_INVIDUAL}
  //       />
  //     );
  //   }
  //   return null;
  // };

  // renderRadioShop = () => {
  //   const { user } = this.props;
  //   const { type } = this.state;
  //   const shopRoster = user?.shopRoster;
  //   if (shopRoster === true) {
  //     return (
  //       <FormControlLabel
  //         value={RADIO_SHOP}
  //         control={<Radio color="primary" />}
  //         label="Shop"
  //         labelPlacement="end"
  //         checked={type === RADIO_SHOP}
  //       />
  //     );
  //   }
  // };

  renderBranchPicker = () => {
    const { branch, type } = this.state;
    const { branchs, user } = this.props;
    const shopRoster = user?.shopRoster;
    if (shopRoster === true) {
      const staffId = user?.id;
      const list = branchs?.filter((item) => item.staffs?.includes(staffId));
      const options = list?.map((item, index) => {
        const value = item?.id;
        const companyName = item?.companyName;
        return (
          <option value={value} key={index}>
            {companyName}
          </option>
        );
      });
      const value = branch ? branch : undefined;
      return (
        <FormControl variant="outlined">
          <Select
            native
            value={value}
            onChange={(event) => this.handleSelectBranch(event)}
            margin="dense"
            disabled={type !== RADIO_SHOP}
          >
            <option value={undefined}>Select Shop</option>
            {options}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  renderBottomNav = () => {
    const { tab } = this.state;
    return (
      <BottomNavigation value={tab} onChange={this.handleChangeTab} showLabels>
        <BottomNavigationAction label="This Week" value={TAB_THIS_WEEK} icon={<TodayOutlined />} />
        <BottomNavigationAction label="Next Week" value={TAB_NEXT_WEEK} icon={<TodayOutlined />} />
      </BottomNavigation>
    );
  };

  renderRosterData = () => {
    return (
      <div style={{ marginTop: 10 }}>
        {this.renderIndividualData()}
        {this.renderShopData()}
      </div>
    );
  };

  renderIndividualData = () => {
    const { lockThis, lockNext, user } = this.props;
    const { tab, type } = this.state;
    const individualRoster = user?.individualRoster;
    if (individualRoster === true) {
      return (
        <React.Fragment>
          <RosterInvidualThisWeekTab
            active={tab === TAB_THIS_WEEK && type === RADIO_INVIDUAL}
            lock={lockThis?.id}
          />
          <RosterInvidualNextWeekTab
            active={tab === TAB_NEXT_WEEK && type === RADIO_INVIDUAL}
            lock={lockNext?.id}
          />
        </React.Fragment>
      );
    }
    return null;
  };

  renderShopData = () => {
    const { lockThis, lockNext, user } = this.props;
    const { tab, type, loadingBranch, staffsList, branch } = this.state;
    const shopRoster = user?.shopRoster;
    if (shopRoster === true) {
      return (
        <React.Fragment>
          <RosterStoreThisWeekTab
            active={tab === TAB_THIS_WEEK && type === RADIO_SHOP}
            loading={loadingBranch}
            staffs={staffsList}
            branch={branch}
            lock={lockThis?.id}
          />
          <RosterStoreNextWeekTab
            active={tab === TAB_NEXT_WEEK && type === RADIO_SHOP}
            loading={loadingBranch}
            staffs={staffsList}
            branch={branch}
            lock={lockNext?.id}
          />
        </React.Fragment>
      );
    }
  };

  // -------------------------- MAIN --------------------------
  render() {
    const { auth } = this.props;
    if (!auth?.uid) return <Redirect to={URL.signin} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm" className="bottom-nav">
          <Navbar title="Roster" subtitle={BRAND_NAME} right={this.renderRightButton()} />
          {this.renderSelectMode()}
          {this.renderRosterData()}
          {this.renderBottomNav()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  user: state.userReducer.user,
  rosters: state.firebaseWeb.rosters,
  branchs: state.firebaseWeb.branchs,
  staffs: state.firebaseWeb.staffs,
  lockThis: state.firebaseWeb.lockThis,
  lockNext: state.firebaseWeb.lockNext,
});

const mapDispatchToProps = {
  getUserInfoFromStorage,
  firestoreSnapshotRoster,
  firestoreSnapshotBranchRoster,
  firestoreSnapShotLockRoster,
  unSubRosters,
  unSubBranchRosters,
  unSnapShotLockRoster,
  firestoreSnapshotUser,
  unSubUser,
  firebaseLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterScreen);
