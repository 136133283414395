import { LOCK_ROSTER_THIS_WEEK_SUCCESS, LOCK_ROSTER_NEXT_WEEK_SUCCESS } from "../ref";

// Tạo model cho Auth
const firebaseModel = {
  loginError: "",
  loading: false,
  error: "",
  user: null,
  staffs: null,
  rosters: null,
  branchRosters: null,
  branchs: null,
  requests: null,
  request: null,
  lockThis: null,
  lockNext: null,
};
// Phương thức thay đổi dữ liệu của model
export default (state = firebaseModel, action) => {
  switch (action.type) {
    case "firebase_loading":
      return {
        ...state,
        loading: true,
      };
    case "firebase_login_success":
      return {
        ...state,
        loginError: "",
        loading: false,
        user: action.payload,
      };
    case "firebase_login_fail":
      return {
        ...state,
        loginError: action.payload,
        loading: false,
      };
    case "firebase_logout_success":
      return {
        ...state,
        loginError: "",
        loading: false,
        user: null,
      };
    case "get_user_list_success":
      return {
        ...state,
        staffs: action.payload,
      };
    case "snapshot_branch_list_success":
      return {
        ...state,
        branchs: action.payload,
      };
    case "snapshot_roster_success":
      return {
        ...state,
        rosters: action.payload,
      };
    case "snapshot_branch_roster_success":
      return {
        ...state,
        branchRosters: action.payload,
      };
    case "snapshot_request_success":
      return {
        ...state,
        requests: action.payload,
      };
    case "set_request_detail_success":
      return {
        ...state,
        request: action.payload,
      };
    case LOCK_ROSTER_THIS_WEEK_SUCCESS:
      return {
        ...state,
        lockThis: action.payload,
      };
    case LOCK_ROSTER_NEXT_WEEK_SUCCESS:
      return {
        ...state,
        lockNext: action.payload,
      };
    default:
      return state;
  }
};
