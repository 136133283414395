export const VERSION = '1.0.0';
export const APP_NAME = 'ProfessioNail Roster';
export const ALL = 'All';
export const WORK = 'Work';
export const OFF = 'Off';
export const LOCAL_USER = 'user';
export const LOCAL_TEMPLATE = 'Local_Template';
export const SYSTEM_EMAIL = 'system_account_do_not_delete@professionail.co.nz';
export const SYSTEM_PASSWORD = 'systemadmin123';
export const SUPPORT_NAME = 'John';
export const SNAPSHOT_ADD = 'added';
export const SNAPSHOT_UPDATE = 'modified';
export const SNAPSHOT_REMOVE = 'removed';
export const RADIO_INVIDUAL = 'invidual';
export const RADIO_SHOP = 'shop';
export const TAB_THIS_WEEK = 'this_week';
export const TAB_NEXT_WEEK = 'next_week';
export const REQUEST_SICK = 'Sick leave';
export const REQUEST_PLACE = 'Change Workplace';
export const REQUEST_TIME = 'Change Worktime';
export const REQUEST_OFF = 'Request Day Off';
export const REQUEST_SENT = 'Sent';
export const REQUEST_REJECTED = 'Rejected';
export const REQUEST_ACCEPTED = 'Accepted';
export const LEVELS = [
    { name: 'Trainee', color: '#fd9644' },
    { name: 'Level 1', color: '#eb3b5a' },
    { name: 'Level 2', color: '#20bf6b' },
    { name: 'Level 3', color: '#4b7bec' },
    { name: 'Level 4', color: '#a55eea' },
];