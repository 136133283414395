import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  firebaseLogout,
  firestoreSnapShotUserList,
  firestoreSnapshotBranchList,
} from "../../store/actions/firebase.action";
import SignInScene from "../01_Login/login";
import RosterScreen from "../02_Roster/roster_board";
import RequestScreen from "../03_Request/request_management";
import RequestAddScreen from "../03_Request/request_add";
import RequestDetailScreen from "../03_Request/request_detail";
import { URL } from "../../config/config";

class Router extends Component {
  componentDidMount = () => {
    this.props.firestoreSnapShotUserList();
    this.props.firestoreSnapshotBranchList();
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={URL.home} component={RosterScreen} exact />
          <Route path={URL.signin} component={SignInScene} />
          <Route path={URL.request} component={RequestScreen} />
          <Route path={URL.requestAdd} component={RequestAddScreen} />
          <Route path={URL.requestDetail} component={RequestDetailScreen} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = {
  firebaseLogout,
  firestoreSnapShotUserList,
  firestoreSnapshotBranchList,
};

export default connect(null, mapDispatchToProps)(Router);
