// firebase ref
export const REF_USER = "user";
export const REF_BRANCH = "branch";
export const REF_REQUEST = "request";
export const REF_ROSTER = "roster";

export const REF_LOCK_ROSTER = "lockRoster";
export const LOCK_ROSTER_THIS_WEEK_SUCCESS = "get_lock_roster_this_week_success";
export const LOCK_ROSTER_NEXT_WEEK_SUCCESS = "get_lock_roster_next_week_success";
export const GET_USER_SUCCESS = "get_user_success";
